import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { saveTableFilterState } from "../../api/gridUtils";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import MyCustomFilters from "../../components/shared/MyCustomFilters";
import SearchInput from "../../components/shared/SearchInput";
import {
  Box,
  HStack,
  IconCTAButton,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useGetFilterStates } from "../../queries/gridUtils";
import { usePermissionStore } from "../../store/permissionStore";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import { EsopPlan, PlanState } from "../../types/EsopPlan";
import { AvailableTableFilters, SaveTableState } from "../../types/Table";
import { determineUserAccessToResource } from "../../utils/auth";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { getFormattedValue, stringToNumberConvertor } from "../../utils/string";
import Avatar from "../esopOverview/Avatar";
import PlanStatusLabel from "../esopOverview/StatusLabel";
import { useTableStateManagement } from "../../components/shared/TableHook";
import { TableId } from "../../constants/TableIdConstants";
import CloudSetting from "../../components/shared/CloudIcon";

type PlanInfoCellProps = {
  plan: EsopPlan;
};

function PlanTableAgGrid({
  planTableData,
  handleAction,
}: {
  planTableData: EsopPlan[];
  handleAction: (plan: EsopPlan, action: Action) => void;
}) {
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();
  const { authority } = useAuthorizationStore();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridApi = useRef<GridApi | any>(null);
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const chartThemes = useMemo<string[]>(() => ["ag-pastel", "ag-vivid"], []);
  const { permission } = usePermissionStore();
  const { data, refetch } = useGetFilterStates("ALLPLANS");

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = planTableData.find(
        (row) =>
          row.esopPlanId ===
          cellParams?.data?.esopPlanState?.props?.plan?.esopPlanId
      );
      if (rowData) {
        navigate(`/options/planView/${rowData.esopPlanId}`);
      }
    }
  };
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.planTable);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const getIndividualValuesFromPlan = (plan: EsopPlan) => {
    const totalShares = getFormattedValue(
      (plan?.totalShares || 0) * (plan?.conversionNumber || 1),
      currency,
      plan?.isFractional
    );
    const issuedShares = getFormattedValue(
      (plan?.totalShares || 0) * (plan?.conversionNumber || 1) -
        (plan?.optionsReserved || 0),
      currency,
      plan?.isFractional
    );
    const optionsReserved = getFormattedValue(
      plan?.optionsReserved,
      currency,
      plan?.isFractional
    );
    return { totalShares, issuedShares, optionsReserved };
  };

  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "PLAN NAME",
        width: 200,
        field: "planName",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) =>
          params.getValue("planName").props.planName,
        initialWidth: 200,
        minWidth: 200,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        filterParams: {
          comparator: sortComparator,
        },
        comparator(valueA, valueB, ..._other) {
          return valueA.props.planName?.localeCompare(valueB.props.planName);
        },
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        getQuickFilterText: (params) =>
          Object.values(params?.data?.planName?.props).toString(),

        // cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value ? value.props : null,

        cellRenderer: PlanInfoRender,
      },
      {
        headerName: "PLAN TYPE",
        field: "planType",
        filter: "agSetColumnFilter",
        filterParams: filterparams,
        menuTabs: ["filterMenuTab"],
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "TOTAL SHARE",
        field: "totalShares",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) =>
          stringToNumberConvertor(params.getValue("totalShares")),
        filterParams: filterparams,
        menuTabs: ["filterMenuTab"],
        getQuickFilterText: (params) => Object.values(params?.data).toString(),

        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "GRANTED",
        field: "issuedShares",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) => params.getValue("issuedShares"),
        filterParams: filterparams,
        menuTabs: ["filterMenuTab"],
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "AVAILABLE",
        field: "optionsReserved",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) => params.getValue("optionsReserved"),
        filterParams: filterparams,
        menuTabs: ["filterMenuTab"],
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "STATE",

        field: "esopPlanState",

        sortable: true,

        filter: "agMultiColumnFilter",
        comparator(valueA, valueB, ..._other) {
          return valueA.props.plan.esopPlanState?.localeCompare(
            valueB.props.plan.esopPlanState
          );
        },
        filterParams: {
          maxNumConditions: 5,
          filters: [
            {
              title: "Plan Status",
              filter: "agSetColumnFilter",

              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const status =
                    params?.data?.esopPlanState?.props?.plan?.esopPlanState;
                  return status; // Custom keyCreator logic
                },
                valueFormatter: (params: any) => {
                  const status = params?.value?.props?.plan?.esopPlanState;
                  return status;
                },
              },
            },
          ],
        },
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        // cellRendererParams: ({ value }: { value: { props: any } }) => value.props,
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value ? value.props : null,

        cellRenderer: PlanInfoCell,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        resizable: false,
        sortable: false,
        menuTabs: ["columnsMenuTab"],
        // cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRendererParams: ({ value }: { value: { props: any } }) =>
          value ? value.props : null,

        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  const rowData = useMemo(
    () =>
      planTableData?.map((plan) => ({
        planName: (
          <PlanInfoRender
            planName={plan.planName}
            planDescription={plan.planDescription}
          />
        ),
        ...getIndividualValuesFromPlan(plan),
        esopPlanState: <PlanInfoCell plan={plan}></PlanInfoCell>,
        planType: plan.planType,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Amend Plan",
                disabled:
                  plan.esopPlanState === PlanState.AMENDMENT ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.OptionsPlan,
                    DefaultAction.Edit
                  ),
              },
              {
                name: "View Plan",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.OptionsPlan,
                  DefaultAction.Read
                ),
              },
              {
                name: "Update Plan Status",
                disabled:
                  plan.esopPlanState === PlanState.ACTIVE ||
                  plan.esopPlanState === PlanState.REJECTED ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.OptionsPlan,
                    DefaultAction.STATE_CHANGE
                  ) ||
                  !plan.isStateChangeAllowed,
              },
              {
                name: "Delete Plan",
                disabled:
                  plan.issuedShares > 0 ||
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.OptionsPlan,
                    DefaultAction.Delete
                  ),
              },
              {
                name: "Upload Plan Document",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.OptionsPlan,
                  DefaultAction.Edit
                ),
              },
              {
                name: "Modify Plan Ownership",
                disabled: !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.OptionsPlan,
                  DefaultAction.Edit
                ),
              },
            ]}
            onAction={(action) => handleAction(plan, action)}
          />
        ),
      })),
    [planTableData]
  );
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api.paginationSetPageSize(Number(value));
  }, []);

  const onGridReady = (params: any) => {
    gridApi.current = params;
  };
  const topGrid = useRef(null);
  const bottomGrid = useRef(null);

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;

    const cleanData = data.childrenAfterAggFilter.map((element: any) => ({
      totalShares: Number(element.data.totalShares.replace(/,/g, "")),
      issuedShares: Number(element.data.issuedShares.replace(/,/g, "")),
      optionsReserved: Number(element.data.optionsReserved.replace(/,/g, "")),
    }));

    const totalShares = cleanData.reduce(
      (accumulator: any, data: any) => accumulator + data.totalShares,
      0
    );
    const issuedShares = cleanData.reduce(
      (accumulator: any, data: any) => accumulator + data.issuedShares,
      0
    );
    const optionsReserved = cleanData.reduce(
      (accumulator: any, data: any) => accumulator + data.optionsReserved,
      0
    );

    const isFractional = data.childrenAfterAggFilter.some(
      (d: any) => d.data.esopPlanState.props.plan.isFractional
    );
    api.setPinnedBottomRowData([
      {
        planName: <PlanInfoRender planName="Total" />,
        totalShares: getFormattedValue(totalShares, currency, isFractional),
        issuedShares: getFormattedValue(issuedShares, currency, isFractional),
        optionsReserved: getFormattedValue(
          optionsReserved,
          currency,
          isFractional
        ),
        actions: "",
        isLastPinnedRow: true,
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilter();
  };

  const saveNewFilter = (filterName: string) => {
    const filterState = gridApi.current?.api.getFilterModel();
    const filterStateToSave: SaveTableState = {
      filterName,
      filterState,
      tableName: "ALLPLANS",
    };
    saveTableFilterState(filterStateToSave).then((_) => {
      toast(`${filterName} saved successfully`, {
        autoClose: 2000,
        type: "success",
      });
      refetch();
    });
  };
  const onFilterSelected = (filterItem: AvailableTableFilters | null) => {
    gridApi?.current?.setFilterModel(filterItem?.filterState);
  };
  return (
    <VStack className="w-full">
      <HStack className="pl-6 p-0 m-0">
        <CloudSetting
          tableId={TableId.planTable}
          isLoading={isSaving}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
      </HStack>
      <HStack className="items-center justify-end bg-white rounded-md">
        <HStack className="items-center justify-end gap-4">
          <HStack className="items-center">
            <SearchInput
              className=""
              placeholder={`Search`}
              onChange={(e: any) => {
                const inputValue = e.target.value.trim();
                const isNumeric = /^[0-9,]+$/.test(inputValue);
                const searchValue = isNumeric
                  ? getFormattedValue(inputValue.replace(/,/g, ""))
                  : inputValue;
                gridApi.current.api.setQuickFilter(searchValue);
              }}
            />
          </HStack>
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
          <MyCustomFilters
            currentFilterState={gridApi.current?.api.getFilterModel()}
            availableFilters={data || []}
            onFilterSelect={onFilterSelected}
            className={""}
            onNewFilterAddition={(e) => {
              saveNewFilter(e);
            }}
          ></MyCustomFilters>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            onRowDataUpdated={setPinnedBottomRowData}
            ref={topGrid}
            getRowStyle={(params) => ({
              background: params.data.isLastPinnedRow ? "#f0f0f0" : "",
              fontWeight: params.data.isLastPinnedRow ? "normal" : "normal",
              color: params.data.isLastPinnedRow ? "black" : "",
            })}
            alignedGrids={bottomGrid.current ? [bottomGrid.current] : undefined}
            onGridReady={onGridReady}
            alwaysShowHorizontalScroll
            suppressRowTransform={true}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            alwaysMultiSort
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            columnDefs={columnDefs}
            onCellClicked={handleCellClick}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

function PlanInfoRender({
  planName,
  planDescription,
}: {
  planName: string;
  planDescription?: string;
}) {
  const isTotal = planName === "Total";

  return (
    <HStack
      className={`px-2 py-2 ${
        isTotal ? " py-4 justify-center items-center" : "align-top"
      }`}
    >
      {isTotal ? null : <Avatar name={planName} />}
      <Box className={`px-2 pb-6 ${isTotal ? "text-center" : ""}`}>
        <p className="text-xs font-medium text-gray-dark">{planName}</p>
        {!isTotal && (
          <p className="capitalize text-xxs text-gray-light text-wrap">
            {planDescription?.toLowerCase()}
          </p>
        )}
      </Box>
    </HStack>
  );
}

const PlanInfoCell: React.FC<PlanInfoCellProps> = ({ plan }) => {
  if (!plan || !plan.esopPlanState) {
    return null;
  }

  return (
    <HStack className="px-2 py-2 align-middle">
      <Box>
        <HStack className="">
          <PlanStatusLabel state={plan.esopPlanState} />
        </HStack>
      </Box>
    </HStack>
  );
};
export default PlanTableAgGrid;
