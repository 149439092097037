import { Icon } from "@iconify/react";
import { useEffect, useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
  UserCircleIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { useMatch, useNavigate } from "react-router";
import * as React from "react";
import { toast } from "react-toastify";
import { useAuthStore, useAuthorizationStore, useCompanyStore } from "../store";

import {
  queryClient,
  useGetUserCompanies,
  useGetUserCompanyDetails,
  useGetUserRoleAndPermission,
  useSwitchCompany,
  useUserDetails,
} from "../queries";
import { HStack, redirectToHissa, VStack } from "../components/utils";
import { Input } from "../components/shared/InputField";
import { usePermissionStore } from "../store/permissionStore";

import { globalFilter } from "../utils/arrays";
import Avatar from "../pages/esopOverview/Avatar";
import { AssociatedCompany, Permission } from "../utils/interfaces/Companies";

export function FlyoutProfile() {
  const [show, setShown] = useState(false);
  const { data: _userData } = useUserDetails();
  const { data: currentCompanyDetails, refetch: refetchUserCompanyDetails } =
    useGetUserCompanyDetails();
  const navigate = useNavigate();
  const { data, refetch: getPermission } = useGetUserRoleAndPermission();
  const { setPermission } = usePermissionStore();
  const { companyData, setCompanyData } = useCompanyStore();
  const { data: userCompanies, isLoading: companiesLoading } =
    useGetUserCompanies();
  if (
    companiesLoading === false &&
    userCompanies &&
    userCompanies?.associatedCompanies?.length === 0
  )
    navigate("/company/createCompany");
  const userData = React.useMemo(() => _userData, [_userData]);
  const { mutate: switchUserCompany } = useSwitchCompany();
  const [successMessage, setSuccessMessage] = useState("");

  async function switchCompany(company: AssociatedCompany) {
    if (company.id === userCompanies?.defaultCompany?.id) return;

    const switchCompanyObject = { companyId: company.id };
    switchUserCompany(switchCompanyObject, {
      onSuccess: (data) => {
        getPermission().then((permission) => {
          setPermission(permission.data as Permission);
        });
        refetchUserCompanyDetails();
      },
      onError: (error) => {
        setSuccessMessage("Failed to switch company. Please try again.");
        toast.error("Failed to switch company. Please try again.", {
          autoClose: 2000,
        });
      },
    });
    queryClient.invalidateQueries();
    queryClient.refetchQueries();
  }

  React.useEffect(() => {
    if (currentCompanyDetails) {
      setCompanyData(currentCompanyDetails);
    }
  }, [currentCompanyDetails]);

  const [loading, setLoading] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const contextRef = React.useRef<HTMLDivElement>(null);
  const contextRef2 = React.useRef<HTMLDivElement>(null);

  const { clearAuthorization } = useAuthorizationStore();
  const { permission, clearPermission } = usePermissionStore();
  const { clear } = useAuthStore();
  const { clearCompanyData } = useCompanyStore();

  const companies = React.useMemo(() => {
    if (!companyData || !userCompanies) return [];

    const filterResult = globalFilter(
      userCompanies.associatedCompanies,
      searchInput,
      ["name"]
    );
    return filterResult;
  }, [searchInput, companyData, userCompanies]);

  function logout() {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("companyId");
    localStorage.removeItem("hissaUrl");
    queryClient.clear();
    clearAuthorization();
    clearPermission();
    clear();
    clearCompanyData();
    redirectToHissa("", true);
  }

  const routes = [
    {
      name: "Profile",
      onClick: () => {
        navigate("/user/profile");
        setShown(false);
        setShowCompanies(false);
      },
      icon: UserCircleIcon,
    },
    {
      name: "Switch Company",
      onClick: () => {},
      icon: ArrowsRightLeftIcon,
    },
    {
      name: "Log Out",
      onClick: () => {
        logout();
        setShown(false);
        setShowCompanies(false);
      },
      icon: ArrowLeftOnRectangleIcon,
    },
  ];
  const profileMatch = useMatch(`/${"/user/profile".split("/")[1]}/*`);

  return (
    <div>
      {loading ? (
        <div className="items-center px-6 py-2">
          <p className="text-sm font-medium animate-pulse">Fetching...</p>
        </div>
      ) : (
        <Popover className="relative">
          <Transition
            show={showCompanies}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              onMouseEnter={() => {
                setShowCompanies(true);
                setShown(true);
              }}
              onMouseLeave={() => {
                setShowCompanies(false);
                setShown(false);
              }}
              ref={contextRef2}
              className="absolute right-0 z-20 flex w-screen mt-20 mr-80 max-w-max "
            >
              {showCompanies && (
                <div className="w-full overflow-y-scroll bg-white border divide-y divide-gray-100 h-72 dropdown-menu min-w-40">
                  <HStack className="sticky top-0 items-center justify-start border border-borderColor bg-slate-light text-slate-dark">
                    <Icon icon="fe:search" width="24" />
                    <Input
                      type="text"
                      value={searchInput}
                      className="w-full font-medium border-0 border-none outline-none text-xs2 bg-inherit"
                      placeholder="Search Company"
                      onChange={(e: any) => setSearchInput(e.target.value)}
                    />
                  </HStack>
                  {companies.map((company, index) => (
                    <li
                      onMouseDown={(e) => {
                        e.preventDefault();
                        switchCompany(company);
                      }}
                      key={index}
                      className="block w-full px-4 py-2 hover:bg-gray-100"
                    >
                      <p
                        className={`items-center justify-between block w-64 ${
                          companyData?.name === company.name
                            ? "text-gray-300  cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                      >
                        {company.name}
                      </p>
                    </li>
                  ))}
                  {companies.length === 0 ? (
                    <ul className="bg-white rounded shadow ">
                      <li className="block px-4 py-2 hover:bg-gray-100">
                        No companies available
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </Popover.Panel>
          </Transition>
          <Popover className="relative">
            <div>
              <Popover.Button
                onClick={() => setShown(!show)}
                onMouseEnter={() => {
                  setShown(true);
                }}
                onMouseLeave={() => {
                  setShown(false);
                  setShowCompanies(false);
                }}
                className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 border-none gap-x-1"
              >
                {userData?.pathToProfilePicture ? (
                  <img
                    className="w-10 h-10 max-h-11 max-w-11"
                    src={userData.pathToProfilePicture}
                    alt=""
                  />
                ) : (
                  <Avatar name={userData?.full_name || ""} />
                )}
                <p className="w-24 text-sm text-gray-500 whitespace-nowrap text-ellipsis overflow-clip">
                  {companyData?.name}
                </p>
                <span className="hidden lg:flex lg:items-center">
                  <ChevronDownIcon
                    className={`ml-2 h-5 w-5 text-gray-400 transition-all transform ${
                      show && "rotate-180"
                    }`}
                    aria-hidden="true"
                  />
                </span>
              </Popover.Button>
            </div>
            <Transition
              show={show}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                onMouseEnter={() => {
                  setShown(true);
                }}
                onMouseLeave={() => {
                  setShown(false);
                  setShowCompanies(false);
                }}
                ref={contextRef}
                className="absolute right-0 z-10 flex w-screen mt-5 max-w-max "
              >
                <div className="flex-auto w-screen max-w-xs overflow-hidden text-sm leading-6 bg-white rounded-lg shadow-lg">
                  <div className="divide-x divide-gray-900/5 bg-gray-50">
                    <div className="relative flex p-4 rounded-lg group gap-x-2 hover:bg-gray-50">
                      <div className="flex items-center justify-center flex-none mt-1 rounded-lg h-11 w-11 bg-gray-50 group-hover:bg-white">
                        {userData?.pathToProfilePicture ? (
                          <img
                            className="w-10 h-10 max-h-11 max-w-11"
                            src={userData.pathToProfilePicture}
                            alt=""
                          />
                        ) : (
                          <Avatar name={userData?.full_name || ""} />
                        )}
                      </div>
                      <VStack className="w-full">
                        <HStack className="items-center justify-between w-full">
                          <a className="flex-1 font-semibold text-gray-900">
                            {userData?.full_name}
                            <span className="absolute inset-0" />
                          </a>
                        </HStack>
                        <p className="text-gray-600 whitespace-nowrap">
                          {userData?.email_id}
                        </p>
                        <p className="text-sm text-gray-500 text-ellipsis overflow-clip">
                          {companyData?.name}
                        </p>
                      </VStack>
                    </div>
                  </div>
                  <div className="p-2 cursor-pointer">
                    {routes.map((item) => (
                      <>
                        <div
                          key={item.name}
                          onClick={() =>
                            item.name === "Switch Company"
                              ? setShowCompanies(!showCompanies)
                              : item.onClick()
                          }
                          className="relative flex items-center p-2 rounded-lg group gap-x-4 hover:bg-gray-50"
                        >
                          <div className="flex items-center justify-center flex-none mt-1 rounded-lg h-11 w-11 bg-gray-50 group-hover:bg-white">
                            <item.icon
                              className={`h-6 w-6 text-gray-600 group-hover:text-primary ${
                                profileMatch && item.name === "Profile"
                                  ? "text-primary"
                                  : ""
                              }`}
                              aria-hidden="true"
                            />
                          </div>
                          <div>
                            <a
                              className={`font-semibold text-gray-900 group-hover:text-primary ${
                                profileMatch && item.name === "Profile"
                                  ? "text-primary"
                                  : ""
                              }`}
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </a>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover>
      )}
    </div>
  );
}
