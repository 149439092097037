/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { useParams, useNavigate } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import {
  CellValueChangedEvent,
  ColDef,
  INumberCellEditorParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Icon } from "@iconify/react";
import { format, isValid } from "date-fns";
import { toast } from "react-toastify";
import {
  useCompleteMilestone,
  useGetMilestoneCompleteDetails,
} from "../../queries/milestone";
import {
  VestState,
  VestingMilestoneCompleteDetails,
  VestingMilestoneState,
} from "../../types/milestone";
import { sortComparator } from "../../utils/agGridUtils";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import StatusLabel from "../esopOverview/StatusLabel";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { CheckMilestoneSummary } from "./CheckMilestoneSummary";
import BCHeader from "../../shared/BCHeader";
import "./milestone.css";
import { useTableStateManagement } from "../../components/shared/TableHook";
import { TableId } from "../../constants/TableIdConstants";
import CloudSetting from "../../components/shared/CloudIcon";

export function CompleteMilestone() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: milestoneCompleteDetails } = useGetMilestoneCompleteDetails(
    id || ""
  );

  const [updateRows, setUpdatedRows] = useState<
    VestingMilestoneCompleteDetails[]
  >([]);

  const [clickedSummary, setClickedSummary] = useState(false);

  const [dialog, setDialog] = useState<{
    open: boolean;
  }>({
    open: false,
  });

  const [milestoneCompleteData, setMilestoneCompleteData] =
    useState<VestingMilestoneCompleteDetails[]>();

  const [searchText, setSearchText] = useState("");
  const gridApi = useRef<AgGridReact<VestingMilestoneCompleteDetails>>(null);

  useEffect(() => {
    const flattenedAndFilteredArray = _.flatMap(
      milestoneCompleteDetails?.data,
      ({ grantId, milestoneDetails }) => {
        const activeMilestoneDetails = milestoneDetails?.filter(
          (detail) => detail.projectedVestingState === VestState.ACTIVE
        );

        return activeMilestoneDetails.map((activeDetail) => ({
          ...activeDetail,
        }));
      }
    );
    if (
      JSON.stringify(flattenedAndFilteredArray) !==
      JSON.stringify(milestoneCompleteData)
    ) {
      setMilestoneCompleteData(flattenedAndFilteredArray);
    }
  }, [milestoneCompleteDetails]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );

  const [colDefs] = useState<ColDef[]>([
    {
      headerName: "GRANT ID",
      field: "grantIdentifier",
      sortable: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 250,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "EMPLOYEE NAME",
      field: "employeeName",
      sortable: true,
      cellStyle: { "line-height": "20px", "padding-top": "15px" },
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "TARGET DATE",
      field: "targetDate",
      valueFormatter: (params) => {
        const formattedDate = params.value
          ? format(new Date(params.value), "dd-MMM-yyyy")
          : "";
        return formattedDate;
      },

      sortable: true,
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          if (dateFromFilter.getTime() === new Date(cellValue).getTime())
            return 0;
          return dateFromFilter.getTime() > new Date(cellValue).getTime()
            ? -1
            : 1;
        },
      },
    },
    {
      headerName: "MILESTONE OPTIONS",
      field: "optionsGranted",
      sortable: true,
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "PROGRESS %",
      field: "uiVestedPercentage",
      sortable: true,
      editable: true,
      cellEditorSelector: (params) => ({
        component: "agNumberCellEditor",
        popup: false,
        cellEditorPopupPosition: "under",
      }),
      cellClass: "milestoneEditor",
      cellEditorParams: {
        min: 0,
        max: 100,
        precision: 4,
        step: 0.01,
        showStepperButtons: true,
      } as INumberCellEditorParams,
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "VESTED OPTIONS",
      field: "optionsVested",
      sortable: true,
      editable: true,
      cellEditor: "agNumberCellEditor",
      cellClass: "milestoneEditor",
      cellEditorPopup: false,
      cellEditorParams: (params: any) => {
        const maxValue = params.data.optionsGranted;
        return {
          precision: 4,
          step: params.data.isFractional ? 0.0001 : 1,
          showStepperButtons: true,
          min: 0,
          max: maxValue,
        } as INumberCellEditorParams;
      },
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: true,
    },
    {
      headerName: "ACTION TO PERFORM ON REMAINING OPTIONS",
      field: "updateState",
      editable: true,
      valueGetter: (params) => params.data.updateState.toString(),
      valueSetter: (params) => {
        if (params.newValue === VestingMilestoneState.DONOTHING) {
          params.data.optionsVested = 0;
          params.data.vestedPercentage = 0;
        }
        if (params.newValue === VestingMilestoneState.EXTEND) {
          params.data.newExtendedDate = new Date();
        }
        params.data.updateState = params.newValue;
        return true;
      },
      cellClass: "milestoneEditor",
      menuTabs: [],
      cellEditorSelector: () => ({
        component: "agSelectCellEditor",
        popup: false,
      }),
      cellEditorParams: {
        values: [
          VestingMilestoneState.DONOTHING,
          VestingMilestoneState.LAPSE,
          VestingMilestoneState.EXTEND,
        ],
      },
    },
    {
      headerName: "NEW END DATE",
      field: "newExtendedDate",
      editable: (params) =>
        params.data.updateState === VestingMilestoneState.EXTEND,
      valueFormatter: (params) => {
        const formattedDate = params.value
          ? format(new Date(params.value), "dd-MMM-yyyy")
          : "";
        return formattedDate;
      },
      cellEditor: "agDateCellEditor",
      cellClass: (params) =>
        params.data.updateState === VestingMilestoneState.EXTEND
          ? "milestoneEditor"
          : null,

      sortable: true,
      comparator: (valueA, valueB, _nodeA, _nodeB, _isDescending) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
      width: 200,
      menuTabs: [],
      autoHeight: true,
      wrapText: true,
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (dateFromFilter: Date, cellValue: any) => {
          if (dateFromFilter.getTime() === new Date(cellValue).getTime())
            return 0;
          return dateFromFilter.getTime() > new Date(cellValue).getTime()
            ? -1
            : 1;
        },
      },
    },
  ]);

  const rowData = useMemo(() => {
    const completeData = milestoneCompleteData?.map((milestone) => {
      const data = {
        employeeName: milestone.employeeName,
        employeeId: milestone.employeeId,
        grantId: milestone.grantId,
        grantIdentifier: milestone.grantIdentifier,
        optionsGranted: milestone.optionsGranted,
        optionsVested: milestone.optionsVested ?? 0,
        vestedPercentage: Number((milestone.vestedPercentage ?? 0).toFixed(2)),
        uiVestedPercentage: Number(
          (milestone.vestedPercentage ?? 0).toFixed(2)
        ),
        newExtendedDate: milestone.newExtendedDate,
        completionDate: milestone.completionDate ?? new Date(),
        notes: milestone.notes ?? "",
        updateState: milestone.updateState,
        milestoneId: milestone.milestoneId,
        projectedVestingId: milestone.projectedVestingId,
        projectedVestingState: milestone.projectedVestingState,
        isFractional: milestone.isFractional,
        milestoneName: milestone.milestoneName,
        targetDate: new Date(milestone.targetDate),
      };
      return data;
    });
    return completeData;
  }, [milestoneCompleteData]);

  const onCellValueChanged = useCallback((event: CellValueChangedEvent) => {
    const { data, colDef, newValue } = event;
    if (colDef.field === "uiVestedPercentage") {
      data.optionsVested = data.isFractional
        ? Number((data.optionsGranted * (newValue / 100)).toFixed(4))
        : Math.floor(data.optionsGranted * (newValue / 100));
      data.vestedPercentage = (data.optionsVested / data.optionsGranted) * 100;
      data.uiVestedPercentage = Number(
        ((data.optionsVested / data.optionsGranted) * 100).toFixed(2)
      );
    } else if (colDef.field === "optionsVested") {
      data.vestedPercentage = (newValue / data.optionsGranted) * 100;
      data.uiVestedPercentage = Number(
        ((newValue / data.optionsGranted) * 100).toFixed(2)
      );
    } else if (colDef.field === "updateState") {
      data.newExtendedDate =
        data.updateState === VestingMilestoneState.EXTEND
          ? data.newExtendedDate
          : undefined;
    }
    gridApi?.current?.api?.applyTransaction({ update: [data] });
  }, []);

  function getAllDataFromTable() {
    const allData: VestingMilestoneCompleteDetails[] = [];
    gridApi.current?.api?.forEachNode((node: any) =>
      allData.push(node.data as VestingMilestoneCompleteDetails)
    );
    return allData;
  }

  function getSummaryData() {
    const allData = getAllDataFromTable();
    return allData.filter(
      (data) => data.updateState !== VestingMilestoneState.DONOTHING
    );
  }

  function handleSummaryData() {
    const data = getAllDataFromTable();
    if (data) {
      for (const vesting of data) {
        if (vesting.updateState === VestingMilestoneState.LAPSE) {
          vesting.newExtendedDate = undefined;
        } else if (vesting.updateState === VestingMilestoneState.EXTEND) {
          if (!isValid(vesting.newExtendedDate)) {
            toast.error("Extend Date is mandatory for Extended grants");
            return;
          }
        }
      }
    }
    setClickedSummary(true);
  }

  function handleBulkAction() {
    const selectedRows = gridApi?.current?.api?.getSelectedRows();
    if (selectedRows && selectedRows?.length > 0) {
      setDialog({ open: true });
    }
  }

  function getSelectedData() {
    const selectedRows = gridApi?.current?.api?.getSelectedRows();
    return selectedRows || [];
  }

  useEffect(() => {
    const currentData = getAllDataFromTable();
    const updatedData = _.uniqBy(
      [...currentData, ...updateRows],
      (data) => data.grantId
    );
    if (updateRows.length > 0) {
      gridApi.current?.api.setRowData(updatedData);
    }
  }, [updateRows]);

  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.completeMilestoneTable);

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter();
  };

  return (
    <>
      <VStack className="gap-4 rounded-lg">
        <BCHeader
          className="items-baseline pb-4"
          bcTitle="Performance Milestones"
          bcSubTitle="Complete milestone"
        />
      </VStack>
      {!clickedSummary ? (
        <Box className="border border-borderColor drop-shadow-box ">
          <HStack className="items-center justify-between py-2 bg-white rounded-md ">
            <Dialog open={dialog.open} maxWidth="md">
              <BulkAction
                onClose={() => setDialog({ open: false })}
                data={getSelectedData()}
                setUpdatedRows={setUpdatedRows}
              />
            </Dialog>
            <VStack className="px-6 lg:justify-start">
              <p className="text-lg font-medium text-gray-600 ">
                Complete Milestone -{" "}
                {milestoneCompleteData &&
                  milestoneCompleteData[0]?.milestoneName}
              </p>
              <p className="mr-4 text-sm font-medium text-gray-500 md:mr-12">
                {`${milestoneCompleteData?.length} grants`}
              </p>
              <HStack className="p-0 m-0">
                <CloudSetting
                  tableId={TableId.completeMilestoneTable}
                  isLoading={isSaving}
                  filterCondition={filterCondition}
                  setFilterCondition={setFilterCondition}
                />
              </HStack>
            </VStack>
            <HStack className="items-center justify-end py-2">
              <HStack className="items-center p-2 rounded min-w-72 bg-slate-light text-slate-dark">
                <Icon icon="fe:search" width="24" className="mr-2 " />
                <input
                  type="text"
                  className="w-full text-xs font-normal border-0 outline-none bg-inherit"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                ></input>
              </HStack>
              <HStack className="pl-8 pr-3">
                <ButtonPrimary1 onClick={() => handleBulkAction()}>
                  Bulk Action
                </ButtonPrimary1>
              </HStack>
            </HStack>
          </HStack>
          <Box
            style={{ height: "calc(84vh - 100px)" }}
            className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
          >
            <AgGridReact
              rowData={rowData}
              quickFilterText={searchText}
              rowSelection="multiple"
              columnDefs={colDefs}
              alwaysMultiSort
              alwaysShowHorizontalScroll
              defaultColDef={defaultColDef}
              animateRows={true}
              suppressCellFocus={true}
              suppressMenuHide={false}
              tooltipShowDelay={1000}
              onCellValueChanged={onCellValueChanged}
              stopEditingWhenCellsLoseFocus={true}
              rowMultiSelectWithClick={true}
              singleClickEdit={true}
              onFilterChanged={onAgGridFilterChanged}
              onColumnEverythingChanged={uploadColumn}
              onColumnResized={uploadColumn}
              rowClass={"border-t border-dashed"}
              overlayNoRowsTemplate={
                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
              }
              ref={gridApi}
              getRowStyle={(params) => {
                if (params.rowIndex % 2 === 0) {
                  return { background: "#f8f8f8" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            />
          </Box>
          <HStack className="justify-end p-2 bg-white ">
            <ButtonPrimary
              className="text-right"
              onClick={() => handleSummaryData()}
            >
              Check Summary
            </ButtonPrimary>
          </HStack>
        </Box>
      ) : (
        <>
          <CheckMilestoneSummary
            data={gridApi}
            setClickedSummary={setClickedSummary}
          />
        </>
      )}
    </>
  );
}

type BulkActionProps = {
  onClose: () => void;
  setUpdatedRows: any;
  data?: VestingMilestoneCompleteDetails[];
};

function BulkAction(props: BulkActionProps) {
  const { onClose, data, setUpdatedRows } = props;
  const [percentage, setPercentage] = useState(0);
  const [remainingOptions, setRemainingOptions] = useState(
    VestingMilestoneState.DONOTHING
  );
  const [newDate, setNewDate] = useState<string>("");

  function applyToGrid() {
    data?.forEach((row) => {
      row.vestedPercentage = percentage;
      row.uiVestedPercentage = Number(percentage.toFixed(2));
      row.optionsVested = row.isFractional
        ? row.optionsGranted * (percentage / 100)
        : Math.floor(row.optionsGranted * (percentage / 100));
      row.updateState = remainingOptions;
      if (remainingOptions === VestingMilestoneState.EXTEND) {
        row.newExtendedDate = new Date(newDate);
      }
    });
    setUpdatedRows(data || []);
    onClose();
  }
  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          Bulk Action
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Percentage</Label>
            <Input
              type="number"
              value={percentage}
              onChange={(e) => setPercentage(Number(e.target.value))}
            />
          </div>
          <div className="flex-1">
            <Label>Remaining Options</Label>
            <Select
              options={[
                VestingMilestoneState.DONOTHING,
                VestingMilestoneState.LAPSE,
                VestingMilestoneState.EXTEND,
              ]}
              value={remainingOptions}
              onChange={(e) =>
                setRemainingOptions(e.target.value as VestingMilestoneState)
              }
            />
          </div>
        </HStack>
        {remainingOptions === "Extend" && (
          <div className="gap-8">
            <Label>New Date</Label>
            <HStack>
              <div className="flex-1">
                <input
                  type={"date"}
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                />
              </div>
              <div className="flex-1"></div>
            </HStack>
          </div>
        )}
        <HStack className="justify-between">
          <ButtonPrimary1 onClick={() => onClose()}>Back</ButtonPrimary1>
          <ButtonPrimary onClick={() => applyToGrid()}>Apply</ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}
