import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  CellClickedEvent,
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  SideBarDef,
  ValueParserParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { useAuthStore } from "../../store";
import { Employee } from "../../types/Employee";
import Tooltip from "../../components/shared/Tooltip";

import {
  Box,
  HStack,
  IconCTAButton,
  VStack,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import { useAuthorizationStore } from "../../store/useAuthorizationStore";
import Avatar from "../esopOverview/Avatar";
import { Action, CTADropdown } from "../../components/shared/Dropdown";

import {
  capitalizeFirstLetter,
  limitString,
  stringFormat,
} from "../../utils/string";
import QuickSearch from "../../components/shared/QuickSearch";
import SearchInput from "../../components/shared/SearchInput";
import { useGetFilterStates } from "../../queries/gridUtils";
import MyCustomFilters from "../../components/shared/MyCustomFilters";
import { saveTableFilterState } from "../../api/gridUtils";
import { AvailableTableFilters, SaveTableState } from "../../types/Table";
import StatusLabelColors from "../../constants/StatusLabelColors";
import { TableId } from "../../constants/TableIdConstants";
import { useTableStateManagement } from "../../components/shared/TableHook";
import CloudSetting from "../../components/shared/CloudIcon";

function EmployeesTableAgGrid({
  handleAction,
  employeeTableData,
  onSelectedRows,
  gridRef,
}: {
  employeeTableData: Employee[];
  handleAction: (action: Action, employees: Employee[]) => void;
  onSelectedRows: (item: Employee[]) => void;
  gridRef: any;
}) {
  const gridApi = useRef<GridApi | any>(null);
  // route params logic
  const isPlaceholderData = false;
  const navigate = useNavigate();
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };
  const { data, refetch } = useGetFilterStates("EMPLOYEES");

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const rowData = employeeTableData.find(
        (row) =>
          row.employeeIdentificationString === cellParams?.data?.employeeId
      );
      if (rowData) {
        navigate(`/options/employeeDetails/${rowData.id}`);
      }
    }
  };

  const saveNewFilter = (filterName: string) => {
    const filterState = gridApi.current?.api.getFilterModel();
    const filterStateToSave: SaveTableState = {
      filterName,
      filterState,
      tableName: "EMPLOYEES",
    };
    saveTableFilterState(filterStateToSave).then((_) => {
      toast(`${filterName} saved successfully`, {
        autoClose: 2000,
        type: "success",
      });
      refetch();
    });
  };
  const onFilterSelected = (filterItem: AvailableTableFilters | null) => {
    gridApi?.current?.api.setFilterModel(filterItem?.filterState);
  };
  function numberValueParser(params: ValueParserParams) {
    const res = Number.parseInt(params.newValue, 10);
    if (Number.isNaN(res)) {
      return undefined;
    }
    return res;
  }
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
    onSelectedRows(selectedRows);
  };
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.employeeTable);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "EMPLOYEE NAME",
        checkboxSelection: true,
        width: 200,
        autoHeight: true,
        cellStyle: { paddingTop: "15px", lineHeight: "20px" },
        wrapText: true,
        headerCheckboxSelection: true,
        field: "employeeName",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) =>
          params.getValue("employeeName").props.employeeName,
        getQuickFilterText: (params) =>
          Object.values(params.data.employeeName.props).toString(),

        initialWidth: 200,
        minWidth: 200,
        sortable: true,
        filterParams: {
          comparator: sortComparator,
        },
        comparator(valueA, valueB, ..._other) {
          return valueA.props.employeeName?.localeCompare(
            valueB.props.employeeName
          );
        },
        menuTabs: ["filterMenuTab"],
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: EmployeeInfoRender,
      },
      {
        headerName: "EMPLOYMENT STATUS",
        field: "employmentStatus",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        comparator(valueA, valueB, ..._other) {
          return valueA?.props?.employmentStatus?.localeCompare(
            valueB?.props?.employmentStatus
          );
        },
        filterValueGetter: (params) =>
          params?.getValue("employmentStatus")?.props?.employmentStatus,
        cellRendererParams: ({ value }: { value: any }) => value.props,
        menuTabs: ["filterMenuTab"],
        cellRenderer: EmployementStatusRender,
      },
      {
        headerName: "DATE OF JOINING",
        width: 200,
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        field: "dateofjoining",
        sortable: true,
        filter: "agMultiColumnFilter",
        autoHeight: true,
        wrapText: true,
        comparator(valueA, valueB, ..._other) {
          return valueA.props.dateOfJoin?.localeCompare(
            valueB.props.dateOfJoin
          );
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Employee Date",
              display: "subMenu",
              filterParams: {
                maxNumConditions: 5,
                inRangeInclusive: true,
                buttons: ["reset"],
                comparator: (
                  filterLocalDateAtMidnight: Date,
                  cellValue: { props: Employee }
                ) => {
                  const filterDate = filterLocalDateAtMidnight;
                  filterDate.setHours(0, 0, 0, 0);
                  const cellDate = new Date(cellValue.props?.dateOfJoin);
                  cellDate.setHours(0, 0, 0, 0);
                  const filterDateAsEpoch = filterDate.valueOf();
                  const cellEpoch = cellDate.valueOf();
                  return cellEpoch - filterDateAsEpoch;
                },
              },
              filter: "agDateColumnFilter",
            },
            {
              title: "Resignation Date",
              display: "subMenu",
              filterParams: {
                maxNumConditions: 5,
                inRangeInclusive: true,
                buttons: ["reset"],
                comparator: (
                  filterLocalDateAtMidnight: Date,
                  cellValue: { props: Employee }
                ) => {
                  const filterDate = filterLocalDateAtMidnight;
                  filterDate.setHours(0, 0, 0, 0);
                  const cellDate = new Date(cellValue.props?.lastDay || "");
                  cellDate.setHours(0, 0, 0, 0);
                  const filterDateAsEpoch = filterDate.valueOf();
                  const cellEpoch = cellDate.valueOf();
                  return cellEpoch - filterDateAsEpoch;
                },
              },
              filter: "agDateColumnFilter",
            },
          ],
        },

        menuTabs: ["filterMenuTab"],
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: EmployeeDate,
      },
      {
        headerName: "EMPLOYEE ID",
        width: 200,
        field: "employeeId",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("employeeId"),
        filterParams: filterparams,
        menuTabs: ["filterMenuTab"],
        comparator(valueA, valueB, ..._other) {
          return valueA?.localeCompare(valueB);
        },
        sortable: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "EMAIL ID",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" || "" },
        field: "email",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("email"),
        filterParams: {
          comparator: sortComparator,
        },
        comparator(valueA, valueB, ..._other) {
          return valueA?.localeCompare(valueB);
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "VESTED FACTOR",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" || "" },
        field: "vestedFactor",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) => params.getValue("vestedFactor"),
        filterParams: {
          comparator: sortComparator,
        },
        valueFormatter: (params) =>
          params.value != null
            ? `${parseFloat(params.value.toFixed(2))}%`
            : "0%",
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "NO OF GRANTS",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" || "" },
        field: "noOfGrants",
        filter: "agNumberColumnFilter",
        filterValueGetter: (params) => params.getValue("noOfGrants"),
        filterParams: {
          comparator: sortComparator,
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "LOGIN STATUS",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" || "" },
        field: "lastlogin",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("lastlogin"),
        filterParams: {
          comparator: sortComparator,
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
      {
        headerName: "CREDENTIALS GENERATED",
        width: 200,
        cellStyle: { lineHeight: "20px", paddingTop: "15px" || "" },
        field: "generateCredential",
        filter: "agSetColumnFilter",
        filterValueGetter: (params) => params.getValue("generateCredential"),
        filterParams: {
          comparator: sortComparator,
        },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        suppressSizeToFit: true,
        autoHeight: true,
        wrapText: true,
      },
    ],
    []
  );

  const rowData = useMemo(
    () =>
      employeeTableData?.map((employee) => ({
        avatar: (
          <Avatar name={employee.employeeName} status={employee.employeeName} />
        ),
        employeeName: (
          <EmployeeInfoRender
            employeeName={employee.employeeName}
            employeeDesignation={employee.employeeDesignation}
            department={employee.department}
            employmentStatus={employee.employmentStatus}
          />
        ),
        dateofjoining: (
          <EmployeeDate
            dateOfJoin={employee.dateOfJoin}
            lastDay={employee.lastDay}
          />
        ),
        employmentStatus: (
          <EmployementStatusRender
            employmentStatus={employee.employmentStatus}
          />
        ),
        employeeId: employee.employeeIdentificationString,
        email: employee.email,
        vestedFactor: employee.totalPercentageVested * 100,
        noOfGrants: employee.noOfGrants,
        lastlogin: employee.lastLogin ? "Yes" : "No",
        generateCredential: employee.isLoginProvided ? "Yes" : "No",
      })),
    [employeeTableData]
  );
  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api.paginationSetPageSize(Number(value));
  }, []);
  const onGridReady = (params: any) => {
    gridApi.current = params;
    gridRef.current = params.api;
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter();
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <VStack className="w-full">
      <HStack className="pl-6 p-0 m-0">
        <CloudSetting
          tableId={TableId.employeeTable}
          isLoading={isSaving}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
      </HStack>
      <HStack className="items-center justify-end gap-4">
        <HStack>
          <SearchInput
            className=""
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </HStack>
        {/* <QuickSearch
          className="px-2"
          placeholder="Search"
          onChange={(e) => {
            gridApi.current.setQuickFilter(e.target.value);
          }}
        /> */}
        <IconCTAButton
          value={"Columns"}
          onClick={() => openToolPanel("columns")}
          iconName={"fluent:column-triple-edit-20-regular"}
          className={`px-4 font-medium items-center flex flex-row ${
            isColumnOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isColumnOpen}
        />
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4  font-medium items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
        <MyCustomFilters
          currentFilterState={gridApi.current?.api.getFilterModel()}
          availableFilters={data || []}
          onFilterSelect={onFilterSelected}
          className={""}
          onNewFilterAddition={(e) => {
            saveNewFilter(e);
          }}
        ></MyCustomFilters>

        {/* <div
            className={`pr-2 ${
              isColumnOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("columns")}>
              Columns
            </button>
          </div>
          <div
            className={`pr-2 ${
              isFilterOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("filters")}>
              Filters
            </button>
          </div> */}
      </HStack>
      {/* <HStack className="items-center justify-end bg-white rounded-md">
        <HStack className="items-center justify-start">
          <QuickSearch
            placeholder="Search by name, status or dept"
            onChange={(e) => {
              gridApi.current.setQuickFilter(e.target.value);
            }}
          />

          <div
            className={`pr-2 ${
              isColumnOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("columns")}>
              Columns
            </button>
          </div>
          <div
            className={`pr-2 ${
              isFilterOpen ? "text-orange-500" : "text-black"
            }`}
          >
            <button className="px-4" onClick={() => openToolPanel("filters")}>
              Filters
            </button>
          </div>
        </HStack>
      </HStack> */}
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            alwaysShowHorizontalScroll
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            onCellClicked={handleCellClick}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            onSelectionChanged={handleSelectionChanged}
            suppressRowClickSelection={true}
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
    </VStack>
  );
}

function EmployeeInfoRender(emp: {
  employeeName: string;
  employeeDesignation: string;
  department: string;
  employmentStatus: string;
}) {
  return (
    <HStack>
      <Avatar name={emp.employeeName} status={emp.employmentStatus} />
      <Box className="ml-2">
        <p className="text-xs font-medium text-gray-dark">{emp.employeeName}</p>
        <p className="capitalize text-xxs text-gray-light">
          {(emp.employeeDesignation !== null
            ? emp.employeeDesignation
            : ""
          ).toLowerCase() || " "}
          |{" "}
          {(emp.department !== null ? emp.department : "").toLowerCase() || " "}
        </p>
      </Box>
    </HStack>
  );
}
function EmployementStatusRender(emp: { employmentStatus: string }) {
  const statuses = ["EMPLOYED", "RESIGNED", "PENDING"];
  const status = emp.employmentStatus?.toUpperCase() as
    | "EMPLOYED"
    | "RESIGNED"
    | "PENDING";
  if (!statuses.includes(status)) return <></>;
  const { color, backgroundColor } = StatusLabelColors[status];
  return (
    <HStack>
      <Box className="ml-2 min-h-4">
        <span
          className={`flex items-center capitalize min-w-10 h-6  rounded-sm font-medium text-center text-[9px]  leading-4 px-2`}
          style={{ color, backgroundColor }}
        >
          {status}
        </span>
      </Box>
    </HStack>
  );
}

function EmployeeDate(emp: { dateOfJoin?: string; lastDay?: string }) {
  const formattedDate = new Date(emp.dateOfJoin || " ")
    .toUTCString()
    .slice(5, 17);
  const formattedLastDay = new Date(emp.lastDay || "")
    .toUTCString()
    .slice(5, 17);
  return (
    <Box className="">
      <HStack>
        <Box>
          <p className="text-xs font-medium text-gray-dark">{formattedDate}</p>
          {emp.lastDay && (
            <p className="capitalize text-xxs text-gray-light">{`Resigned on: ${formattedLastDay}`}</p>
          )}
        </Box>
      </HStack>
    </Box>
  );
}

export default EmployeesTableAgGrid;
