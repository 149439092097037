import { QueryFunctionContext } from "react-query";
import captableApi from "./captableApi";
import { useAuthStore } from "../store";
import { useGetUserCompanies } from "../queries";

export interface FilterConditionModel {
  isFilterSave: boolean;
  isColumnSave: boolean;
}
export interface TableStateModel {
  filterState: any;
  columnState: any;
  isFilterSave: boolean;
  isColumnSave: boolean;
}
export async function getTableFilterState(
  context: QueryFunctionContext
): Promise<any> {
  const tableId = context.queryKey[1];
  const companyId = context.queryKey[2];
  return captableApi
    .get(`v1/utils/tableState`, {
      params: {
        id: `${tableId}${companyId}`,
        platform: "OPTIONS",
      },
    })
    .then((res) => res.data.data);
}

export async function postTableFilterState({
  tableId,
  companyId,
  filterData,
}: {
  tableId: string;
  companyId: string;
  filterData: TableStateModel;
}): Promise<any> {
  const postData = {
    id: `${tableId}${companyId}`,
    data: filterData,
    companyId,
    platform: "OPTIONS",
  };
  return captableApi
    .put(`v1/utils/tableState`, postData)
    .then((res) => res.data);
}
export async function deleteTableState({
  tableId,
  companyId,
}: {
  tableId: string;
  companyId: string;
}): Promise<any> {
  return captableApi
    .delete(`v1/utils/tableState?id=${tableId}${companyId}&platform=OPTIONS`)
    .then((res) => res.data);
}
